import React from "react"

import Layout from "components/Layout/de"
import Product from "components/Layout/Product/index"
import productImg from "./product.png"
import productIcon from "./MoonvisionToolbox.svg"
import { Table } from "react-bootstrap"
import { MdDone } from "react-icons/md"
import { FormattedMessage } from "react-intl"

export default function(props) {
  const description = (
    <React.Fragment>
      <p>
        Die MoonVision Toolbox ist das Effizienteste Tool zur Erstellung von
        Modellen im Rahmen der visuellen automatisierten Qualitätsprüfung. Es
        unterstützt Sie bereits ab dem ersten Bild und stellt sicher, dass Sie
        auch in Zukunft die Kontrolle über die Erkennung haben.
      </p>
      <p>
        Für ein besseres Verständins der Möglichkeiten unserer Software Toolbox
        ist nun die Verwendung mit begrenztem Unfang gratis möglich.
      </p>
      <a href="https://app.moonvision.io/signup" className="btn btn-primary">
        Ohne Verbindlichkeit ausprobieren
      </a>
    </React.Fragment>
  )
  return (
    <Layout location={props.location}>
      <Product
        id="MoonVision Toolbox"
        title="Die Computer Vision Toolbox für Domänen Experten"
        subtitle="Das leistungsfähigste Toolset auf dem Markt.
"
        description={description}
        productImg={productImg}
        productIcon={productIcon}
        productFeatures={[
          "Lernendes System",
          "Einfache Installation",
          "Hardware unabhängig",
        ]}
        technicalTable={
          <Table>
            <thead>
              <tr>
                <th colSpan="2">
                  <FormattedMessage id="sliderBenefits" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <FormattedMessage id="sliderHeading1" /> -{" "}
                  <FormattedMessage id="sliderText1" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="sliderHeading2" /> -{" "}
                  <FormattedMessage id="sliderText2" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="sliderText3" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="sliderText4" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="sliderText5" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="toolBoxVideo" />
                </td>

                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="toolBoxManufacturing" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
              <tr>
                <td>
                  <FormattedMessage id="toolBoxAdaptation" />
                </td>
                <td>
                  <MdDone />
                </td>
              </tr>
            </tbody>
          </Table>
        }
      >
        <React.Fragment>
          <h3>Bahnbrechende Ergebnisse mit nur wenigen Bildern</h3>
          <p>
            Unsere Trainingspipeline liefert ab 11 Bildern erstaunliche
            Ergebnisse. Kleine Datensätze ermöglichen das autonome
            kontinuierliche Weiterleiten und das Einsatzen interner Experten.
          </p>
          <h3>Einfaches Echtzeit-Datenmanagement</h3>
          <p>
            Die Verwaltung von Datensätzen kann schwierig sein, wenn die
            Datensätze wachsen und mehr Menschen sie ansehen und bearbeiten
            müssen. Wir haben alles an einem Ort, damit Sie und Ihr Team
            parallel daran arbeiten können und sich auf die Erstellung von
            Datensätzen höchster Qualität konzentrieren können. Unsere
            Echtzeit-Ansichten werden während der Etikettierung aktualisiert und
            geben einen sofortigen Einblick in Ihre Daten.
          </p>
          <h3>Annotation der nächsten Ebene</h3>
          <p>
            Nicht jede Annotation eines Datensatzes kann ausgelagert werden,
            wenn Domänenkenntnisse erforderlich sind. Wir gehen einen völlig
            neuen Weg, indem wir nur wenige beschriftete Bilder als
            Trainingsdaten für unsere Computer Vision Modelle benötigen. Wir
            verwenden "Transfer-Learning", sowie "Few-Shot Detection" und
            spezielle Trainingsverfahren, um großartige Ergebnisse mit sehr
            wenig Zeitaufwand Ihrer Domain-Experten zu erzielen.
          </p>
          <h3>Automatisierte Generierung von Etiketten</h3>
          <p>
            Generieren Sie automatisch Entitäten von Objekten und segmentieren
            Sie auf Basis vortrainierter Netzwerke, um den Etikettierungsprozess
            zu beschleunigen.
          </p>
          <h3>Einzelbild-Empfehlungssystem</h3>
          <p>
            Die Ettiketierung ist aufwendig - wir haben die beste
            Label-Empfehlungssystem auf dem Markt im Jahr 2019. Die Genauigkeit
            von 90+ Prozent ohne unserer Unterstützung beginnt bereits mit einem
            beschrifteten Bild. Wir versichern ihnen schnellste
            Markierergebnisse.
          </p>
          <h3>Szenenerkennung und Clustering</h3>
          <p>
            Die Datengenerierung mit Videos ist kostengünstig und einfach. Die
            Extraktion der relevanten Momente ist hingegen aufwendig. Die
            Toolbox stellt eine neue und äußerst effiziente Methode der
            Datenextraktion dar.
          </p>
          <h3>Zusammenarbeit mit Ihrem Team</h3>
          <p>
            Laden Sie Ihre Kollegen ein und bilden Sie Teams, so dass Sie Daten
            gemeinsam verwalten können. Stellen Sie sicher, dass sich Ihre
            Kollegen nur auf die wichtigen Aufgaben konzentrieren. Erhalten Sie
            erweiterte Analysen, wie z.B. wie viel noch zu kennzeichnen ist.
          </p>
        </React.Fragment>
      </Product>
    </Layout>
  )
}
